<template>
  <div>
    <form-label
      :label="t('validation.attributes.days')"
      class="block mb-1"
    />

    <div class="flex flex-wrap gap-2 mb-3">
      <!-- List each days of the week as card -->
      <app-card-item
        v-for="(day, i) in days"
        :key="i"
        :class="`duration-200 p-2 cursor-pointer flex items-center justify-center ${isDaySelected(day) ? 'text-white' : ''}`"
        :background-classes="isDaySelected(day) ? 'bg-theme-500' : ''"
        @click="toggleDay(day)"
      >
        <app-icon
          library="coolicon"
          :name="isDaySelected(day) ? 'checkbox_square' : 'checkbox'"
          :class="`mr-2 ${isDaySelected(day) ? 'text-white' : ''}`"
        />

        {{ t(`enums.DaysOfWeekEnum.${upperCase(day)}`) }}
      </app-card-item>
    </div>

    <form-hint
      :hint="t('form.hints.point_of_interest.opening_days')"
    />

    <form-group
      type="hidden"
      :name="name"
    />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { Info } from 'luxon'
import { get, upperCase } from 'lodash'
import { fromJS } from 'immutable'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // Prefix used for form group's names
  name: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

// Days of the week, ordered
const days = Info.weekdays('long', {
  locale: 'en',
})

const form = inject('form', ref({}))

const selectedDays = computed(() => (
  get(form.value.values, props.name) ?? []
))

function getSelectedDaysCopy() {
  return fromJS(selectedDays.value).toJS()
}

function handleAdd(day) {
  const selectedDaysCopy = getSelectedDaysCopy()
  selectedDaysCopy.push(day)
  form.value.setFieldValue(props.name, selectedDaysCopy)
}

function handleRemove(dayIndex) {
  const selectedDaysCopy = getSelectedDaysCopy()
  selectedDaysCopy.splice(dayIndex, 1)
  form.value.setFieldValue(props.name, selectedDaysCopy)
}

// Check if a day is selected
function isDaySelected(day) {
  return selectedDays.value.includes(day)
}

function toggleDay(day) {
  if (isDaySelected(day)) {
    // Remove from selected days
    const dayIndex = selectedDays.value.indexOf(day)
    handleRemove(dayIndex)
  } else {
    // Add to selected days
    handleAdd(day)
  }
}
</script>
